import Cookies from "js-cookie";

class KeyService {
    constructor(){
        this.tokenKey = "token";
    }

    setToken(token , options = {expires:7 , path:'/'}){
        Cookies.set(this.tokenKey , token , options);
    }

    getToken(){
        return Cookies.get(this.tokenKey);
    }

    remove(){
        Cookies.remove(this.tokenKey);
    }
}

const keyServices =  new KeyService();
export default keyServices;