import React, { useEffect, useState } from 'react'
import CardCustom from '../../components/Cards/CardCustom'
import './home.css'
import Card_Form from '../../components/Cards/Card_Form';
import { useNavigate } from 'react-router-dom';
import { user_Formdata_fetch_From_Server } from '../../API/Fetch_data';
import AppBarCustom from '../Appbar/AppBar';
import { delete_Form_data_from_Db } from '../../API/Delete_data';
import { useParams } from 'react-router-dom';
import Cookies from 'js-cookie';
import Header from '../Header/Header';
import { useDispatch,useSelector } from 'react-redux';
import { setProjectId } from '../../redux/reducerIdStore';
const Home = () => {
const projectId = useSelector((state)=>state.projectId.projectid)
  const navigate = useNavigate();


  const [formdata,setFormdata] = useState([]);

  const navigateToNext=(path,params)=>{
    navigate(path,{state:params});
  }
 
  useEffect(()=>{
    user_formData_fetch();
  },[])

  const user_formData_fetch=async()=>{
    const userforms = await user_Formdata_fetch_From_Server(projectId);
    console.log("userForms_____",userforms);
    if(userforms.data){
      const form = userforms.data;
      console.log("forms $$$$____",form);
      setFormdata(form.parentForm);
    }

  }

  const delete_form=async(Id)=>{
    console.log("Delete_id",Id);
    const deleteForm =await delete_Form_data_from_Db(Id);
    console.log(deleteForm);
    if(deleteForm.data){
      user_formData_fetch();
    }
    else{
      alert("Somthing went wrong");
    }
  }

  return (
    <>
  {/* <AppBarCustom/> */}
  <Header/>
     <div className='home-outer-container'>
      <div className='form-home-title-container'>
       <h3 className='home-title'>Create your forms</h3>
       <button className='form-home-button'>Send Email</button>
      </div>
        <div className='home-inner-container'>
          {/* <div className='home-card-container' > */}
          <CardCustom HandleClick={()=>{navigateToNext('/createform')}}/>
          {/* </div> */}
           
            {/* <Card_Form/> */}

                 {/* Dynamic Cards */}
        {/* <div className='dynamic-cards-container'> */}
          {formdata.map((item ,index) => (
            <Card_Form Title={item.formTitle}  Id={item._id} DeleteForm_Data={delete_form} updateForm ={()=>{navigateToNext('/updateform',{formId:item._id})}} ClickView={()=>{navigateToNext('/viewforms',{formId:item._id})}} key={index} />
          ))}
        {/* </div> */}
        </div>
      
    </div>
    </>
   
  )
}

export default Home;
