
import { useRef, useState } from 'react';
import './header.css';
import { useEffect } from 'react';
import Cookies from 'js-cookie';
import { useNavigate } from 'react-router-dom';

const Header = () => {
    const [dropdownOpen, setDropdownOpen] = useState(false);
    const dropdownRef = useRef(null);
    const navigate = useNavigate();
    useEffect(()=>{
        const handleClickOutside = (event) => {
            if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
              setDropdownOpen(false);
            }
          };
          document.addEventListener('click', handleClickOutside);
          return () => {
            document.removeEventListener('click', handleClickOutside);
          };
    },[])
   const handleLogout =()=>{
    Object.keys(Cookies.get()).forEach(cookieName => {
      Cookies.remove(cookieName);
    })
    sessionStorage.removeItem("token");
    navigate('/');
   }
  return (
    <div className='fomrm-header-container'>
        <div className='form-header-left-container'>
         <div className='form-log-text-wraper'>
         <div className='form-logo-container' onClick={()=> navigate('/project')}>
            <img className='form-logo-style' src={require('../../images/files.png')} />
          </div>
          <p className='form-logo-text'>ICT-FORMS</p>
         </div>
        </div>
        <div className='form-header-right-container'>
          <div className='form-profile-header' ref={dropdownRef}>
          <div className='form-img-container' onClick={() => setDropdownOpen(!dropdownOpen)}>
           <img className='form-img-style' src={require('../../images/user2.png')} />
           </div>
           <p className='form-profile-name'>John Honay</p>
          </div>
          {dropdownOpen && (
              <div className='dropdown-menu'>
              <div className='dropdown-item'>
              <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" width="20" height="20" stroke-width="2">
                <path d="M10.325 4.317c.426 -1.756 2.924 -1.756 3.35 0a1.724 1.724 0 0 0 2.573 1.066c1.543 -.94 3.31 .826 2.37 2.37a1.724 1.724 0 0 0 1.065 2.572c1.756 .426 1.756 2.924 0 3.35a1.724 1.724 0 0 0 -1.066 2.573c.94 1.543 -.826 3.31 -2.37 2.37a1.724 1.724 0 0 0 -2.572 1.065c-.426 1.756 -2.924 1.756 -3.35 0a1.724 1.724 0 0 0 -2.573 -1.066c-1.543 .94 -3.31 -.826 -2.37 -2.37a1.724 1.724 0 0 0 -1.065 -2.572c-1.756 -.426 -1.756 -2.924 0 -3.35a1.724 1.724 0 0 0 1.066 -2.573c-.94 -1.543 .826 -3.31 2.37 -2.37c1 .608 2.296 .07 2.572 -1.065z"></path>
                <path d="M9 12a3 3 0 1 0 6 0a3 3 0 0 0 -6 0"></path>
                </svg>
                Settings
              </div>
              <div className='dropdown-item' onClick={handleLogout}>
                <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" width="20" height="20" stroke-width="2">
                <path d="M14 8v-2a2 2 0 0 0 -2 -2h-7a2 2 0 0 0 -2 2v12a2 2 0 0 0 2 2h7a2 2 0 0 0 2 -2v-2"></path>
                <path d="M9 12h12l-3 -3"></path>
                <path d="M18 15l3 -3"></path>
                </svg>
                Logout
              </div>
            </div>
          )}
        
        </div>
    </div>
  )
}

export default Header