import { useState } from 'react'
import Buttton18 from '../../Buttons/Buttton18'
import InputField from '../../InputField/inputField'
import './signup.css'
import { validateEmail, validateMobile, validateText } from '../../../Validation/Validation'
import { userSignUp } from '../../../API/Register'
import { ToastContainer, toast } from 'react-toastify';
const SignupBox = ({pageChange}) => {
    const [user , setUser] = useState({
       name:'',
       email:'',
       mobile:'',
       password:'',
       confirmpassword:'',
    });
    const [errors , setErrors] = useState({
        name:'',
        email:'',
        mobile:'',
        password:'',
        confirmpassword:'',
    });
    const handleChange =(e)=>{
        const {name , value} = e.target;
        setUser({...user,[name]:value});
        setErrors({...errors,[name]:''})
    }
    const validationText = ()=>{
        console.log("validtae name",validateText(user.name))
        const newObj = {};
        if(user.name.trim() ==''){
            newObj.name = 'Required'
        }
        if(user.mobile.trim() ===''){
            newObj.mobile = 'Required'
        }
        if(user.email.trim() === ''){
            newObj.email = 'Required'
        }
        if(user.password.trim() === ''){
            newObj.password = 'Required'
        }
        if(user.confirmpassword.trim() === ''){
            newObj.confirmpassword = 'Required'
        }
        if(user.password.trim() !=='' && user.confirmpassword.trim() !== '' && user.password.trim() !== user.confirmpassword.trim()){
            newObj.confirmpassword = 'Password Mismatch'
        }
        if(user.name.trim() !=='' && !validateText(user.name)){
            newObj.name = 'Invalid Name'
        }
        if(user.email.trim() !=='' && !validateEmail(user.email)){
            newObj.email = 'Invalid Email'
        }
        if(user.mobile.trim() !=='' && !validateMobile(user.mobile)){
            newObj.mobile = 'Invalid Mobile'
        }
        

        setErrors({...errors,...newObj});
        return Object.keys(newObj).length === 0;
    }
    const signInSubmit= async()=>{
        const validate = validationText();
        if(validate){
            const res = await userSignUp(user)
            if(res.data){
                setUser({
                    name:'',
                    email:'',
                    mobile:'',
                    password:'',
                    confirmpassword:'',
                });
                toast.success(res.data.message)
                pageChange()
            }
            else{
                if(res.response.data.message){
                    toast.error(res.response.data.message)
                  }
                  else{
                    toast.error(res.response.data.errors[0].msg);
                  }
            }
        }
    }
  return (
    <>
    <div className='signup-box'>
        <h1 style={{marginBottom:'1rem'}}>Sign up</h1>
        <div className='inputfiled-container'>
            <label>Full Name</label>
            <InputField name={'name'} onChange={handleChange} value={user.name} font={'small'} placeholder={'Full Name'} />
            <p className='form-error-txt'>{errors.name}</p>
        </div>
        <div className='signup-grid'>
        <div  className='inputfiled-container'>
            <label>Mobile Number</label>
            <InputField name={'mobile'} onChange={handleChange} value={user.mobile} font={'small'} placeholder={'Mobile'} />
            <p className='form-error-txt'>{errors.mobile}</p>
        </div>
        <div className='inputfiled-container'>
            <label>Email</label>
            <InputField name={'email'} onChange={handleChange} value={user.email} font={'small'} placeholder={'Email'} />
            <p className='form-error-txt'>{errors.email}</p>
        </div>
        </div>
        <div  className='inputfiled-container'>
            <label>Password</label>
            <InputField name={'password'} type={'password'} onChange={handleChange} value={user.password} font={'small'} placeholder={'Password'} />
            <p className='form-error-txt'>{errors.password}</p>
        </div>
        <div  className='inputfiled-container'>
            <label>Confirm Password</label>
            <InputField name={'confirmpassword'} type={'password'} onChange={handleChange} value={user.confirmpassword} font={'small'} placeholder={'Confirm Password'} />
            <p className='form-error-txt'>{errors.confirmpassword}</p>
        </div>
        <Buttton18 Click={signInSubmit}  label={'Sign Up'} /> 
        <p className='login-txt'>If You are already registred ? <span onClick={pageChange}>Login</span></p>
    </div>
    <ToastContainer
        position='top-right'
        autoClose={2000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
        theme='light'
      />
    </>
  )
}

export default SignupBox