import { createSlice } from '@reduxjs/toolkit' //createSlice from '@reduxjs/toolkit'

const initialState = {
    projectid: null,
    formid:null,
};

export const reducerIdStore = createSlice({
    name: 'reducerIdStore',
    initialState,
    reducers: {
        setProjectId: (state, action) => {
            state.projectid = action.payload;
        },
        removeProjectId: (state) => {
            state.projectid = null;
        },
        setFormId:(state,action)=>{
            state.formid = action.payload;
        },
        removeFormId:(state)=>{
            state.formid = null;
        }
    },
});

export const { setProjectId ,setFormId} = reducerIdStore.actions;

export default reducerIdStore.reducer;