import './inputfield.css';

const InputField = ({value,name,placeholder,onChange,onFocus,onBlur,type,font}) => {
  return (
    <>
     <input className="textfield" 
        style={{fontSize:font}}
          placeholder={placeholder}
          name={name}
          value={value}
          onChange={onChange}
          onFocus={onFocus}
          onBlur={onBlur}
          type={type}

        />
    </>
  )
}

export default InputField