import React from 'react'
import './card2.css'

const Card2 = ({item,onClick}) => {
  return (
    <div
//   key={project.id}
onClick={onClick}
  className="project-card"
>
  <div className="project-header">
  <svg
  className="project-icon"
  width="20"
  height="20"
  viewBox="0 0 24 24"
  fill="none"
  xmlns="http://www.w3.org/2000/svg"
>
  <path
    d="M10 4L12 6H20C21.1 6 22 6.9 22 8V18C22 19.1 21.1 20 20 20H4C2.9 20 2 19.1 2 18V6C2 4.9 2.9 4 4 4H10Z"
    fill="currentColor"
  />
</svg>

    <h3 className="project-title">{item.projectName}</h3>
  </div>
  <p className="project-description">{item.projectDescription}</p>
  <div className="project-footer">
    <span className="project-forms-count">
      {'12'} {'forms'}
    </span>
    <span className="project-date">
      {new Date().toDateString(item.createdAt)}
    </span>
  </div>
</div>

  )
}

export default Card2