
import axios from "axios";
import { Url_fetch } from "../UrlConnection/Url";
import keyServices from "../service/KeyServices";
import { useSelector } from "react-redux";

const url = Url_fetch();


export const Create_form=async(title,formData,generateUrl,formTitleImage,projectId)=>{
    try{
        
        const uploadData = await axios.post(

            `${url}/createForms`,
            {
              formTitle: title,
              formUrl:generateUrl,
              formData: formData,
              formTitleImage:formTitleImage,
              projectId:localStorage.getItem('projectId'),
            },
            {
              headers:{
                  Authorization: keyServices.getToken(),
                    "Content-Type": "multipart/form-data",       
              },
            }
          );
          return uploadData;
    }
    catch(error){
        return  error;
    }
}
