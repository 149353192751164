import React, { useEffect, useState } from 'react'
import './listStyle.css';
import StickyHeadTable from '../../components/StickyheaderTable/StickyheaderTable';
import AppbarAdmin from '../Appbar/Appbar';
import { fetchAllFormsDataWithUSer } from '../../API/SperAdmin';
import { PDFDownloadLink, PDFViewer, Document, Page } from '@react-pdf/renderer';
import PdfTable from '../Pdf_download/Pdf_table';

import jsPDF from 'jspdf';
import 'jspdf-autotable';


const ListOutAllForms = () => {

  const [formData, setFormData] = useState([]);
  const [loading, setLoading] = useState(true);
  const [tabledata , setTabledata] = useState([]);

  useEffect(() => {
    fetchAllFormDataWithUserDetails();
  }, []);

  const fetchAllFormDataWithUserDetails = async () => {
    const formsdata = await fetchAllFormsDataWithUSer();
    console.log("form--data--------",formsdata);
    if (formsdata.data) {
      setFormData(formsdata.data.parentFormsWithCounts);
      setLoading(false);
      const dataFetch = formsdata.data.parentFormsWithCounts;
      const processdata = dataFetch.map((row,index)=>({
        Number: index + 1, // Use ID if available, otherwise use index
        Name: row.parentForm.userId ?row.parentForm.userId.name:'-',
        Email: row.parentForm.userId ? row.parentForm.userId.email : '-',
        FormTitle: row.parentForm.formTitle,
        Created: convertToDateFormat(row.parentForm.createdAt),
        Count: row.participantCount,
        Status: row.parentForm.status === '0' ? 'Active' :
                row.parentForm.status === '1' ? 'Closed' :
                row.parentForm.status === '2' ? 'Deleted' : '-',
      }))
      const nestedArray = processdata.map(obj => Object.values(obj));
      setTabledata(nestedArray)
    } 
  else {
      // Handle error
    }
  };

  const convertToDateFormat = (unformattedDate) => {
    const date = new Date(unformattedDate);
    const formatDate = date.toLocaleDateString('en-GB');
    return formatDate;
  };

  const downloadTableAsPDF = () => {
    const doc = new jsPDF();
    const columns = Object.keys(tabledata[0]); // Extract column names
    console.log('tabldata',tabledata)
    // Optional: Customize table styling
    const heading = 'Forms List and User Details';
    const fontSize = 16; 
    
    doc.text(heading, doc.internal.pageSize.getWidth() / 2, 10, { // Center the heading
      align: 'center',
      fontSize: fontSize,
    });
    doc.autoTable({
      startY: 20, // Adjust starting position
      head: [['Number','Name','Email','FormTitle','Created','Count','Status']],
      body: tabledata,
      theme: 'striped', 
      // styles: {
      //   head: { 
      //     fillColor: 'rgb(0,191,255)', 
      //     textColor: '#f8f8ff', 
      //     fontSize: 11, 
      //     fontStyle: 'bold',
      //     halign: 'center',
      //   },
      // },
    });

    doc.save('table_data.pdf'); // Set the filename
  };

  return (
    <>
      <AppbarAdmin />
      <div className='super-admin-viewlist'>
        <div className='export-button-view'>
          {/* <button className='export-button'> */}
          {/* {!loading && (
            <PDFDownloadLink
              document={<PdfTable rows={formData} />}
              fileName='table_details.pdf'
            >
              {({ loading: pdfLoading }) => (pdfLoading ? 'Loading document...' : 'Export')}
            </PDFDownloadLink>
          )} */}
          {/* </button> */}
           <button className='export-button' onClick={downloadTableAsPDF}> Export </button>
        </div>
        <div className='super-table-view-container'>
          <StickyHeadTable rows={formData} />
        </div>
      </div>
    </>
  );
};

export default ListOutAllForms;
