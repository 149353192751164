import React, { useEffect } from 'react'
import Box from '@mui/material/Box';
import Tab from '@mui/material/Tab';
import TabContext from '@mui/lab/TabContext';
import TabList from '@mui/lab/TabList';
import './style.css';
import Reg_User from '../RegisterdUser/Reg_User';
import Form_Settings from '../FormSettings/Form_Settings';
import Form_Update from '../UpdateForm/Form_Update';
import { useLocation } from 'react-router-dom';
import AppBarCustom from '../../container/Appbar/AppBar';
import AppbarAdmin from '../../supperAdmin/Appbar/Appbar';
import Header from '../../container/Header/Header';
import { useNavigate } from 'react-router-dom';
const ViewForms = () => {
  const location  = useLocation();
const navigate = useNavigate();
    const {formId,user} = location.state;

    const [value, setValue] = React.useState('1');

    const handleChange = (event, newValue) => {
      setValue(newValue);
    };

  useEffect(()=>{
    console.log("FormId_________",formId);
  },[])

const renderTabContent=(tabvalue)=>{
    console.log("selected tab",tabvalue);
    switch(tabvalue){
        case '1':
            return (<>dashboard</>)
        case '2':
            return ( <Reg_User formId={formId}/>)
        case '3':
            return (<Form_Settings formId={formId}/>)
        default:
            return null;
    }

}
  return (
    <>
    {user==='admin'?<AppbarAdmin/> :<Header/>}
    
      <Box sx={{ width: '100%', typography: 'body1'}} >
      <TabContext value={value}>
        <Box sx={{ borderBottom: 1, borderColor: 'divider',display:'flex',justifyContent:"center",position:'relative'}}>
          <button onClick={()=> navigate('/home/7657587')} className='form-view-backbutton'>
          <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" width="20" height="20" stroke-width="2">
            <path d="M5 12l14 0"></path>
            <path d="M5 12l6 6"></path>
            <path d="M5 12l6 -6"></path>
          </svg>
        </button>
          <TabList  onChange={handleChange} aria-label="lab API tabs example">
            <Tab label="Dashboard" value="1" />
            <Tab label="Registration" value="2" />
            <Tab label="Settings" value="3" />
          </TabList>
        </Box>
      {renderTabContent(value)}
      </TabContext>
    </Box>



    
    </>
    
  )
}

export default ViewForms
