import React, { useEffect, useState } from 'react'
import './projectlist.css'
import Card2 from '../../components/Card2/Card2'
import CreateProject from '../../components/modalCreateProject/CreateProject';
import Cookies from 'js-cookie';
import { useNavigate } from 'react-router-dom';
import Header from '../../container/Header/Header';
import { getchAllProjects } from '../../API/Project';
import { useDispatch, useSelector } from 'react-redux';
import { setProjectId } from '../../redux/reducerIdStore';
import keyServices from '../../service/KeyServices';
const ProjectList = () => {
    const [openCreateProject , setOpenCreateproject] = useState(false);
    const token = keyServices.getToken();
    const [project , setProject] =  useState([]);
    const dispatch = useDispatch();
    useEffect(()=>{
      projectFetchfromDB();
    },[])

    const projectFetchfromDB = async()=>{
      const response =  await getchAllProjects();
      if(response.data){
        setProject(response.data.projects);
      }
    }
    const navigate = useNavigate();

    // handle project click
    const handleProjectClick=(item)=>{
      dispatch(setProjectId(item._id));
      localStorage.setItem('projectId',item._id);
      console.log("item",token);
      navigate(`/home/${token}`);
    }
  return (
<>
<Header/>
<div className='project-container'>
       <div className='project-header-list'>
            <button onClick={()=>setOpenCreateproject(true)} className='project-button'>  <svg  width="10" height="10" viewBox="0 0 16 16">
                    <path fill='white' d="M15 7H9V1c0-.6-.4-1-1-1S7 .4 7 1v6H1c-.6 0-1 .4-1 1s.4 1 1 1h6v6c0 .6.4 1 1 1s1-.4 1-1V9h6c.6 0 1-.4 1-1s-.4-1-1-1z" />
                  </svg>
                  Create Project</button>
       </div>

       <div className='project-list'>
        <h1>List project</h1>
        <div className='project-list-grid'>
          {project.map((item,index)=>(
             <Card2 key={index} item={item}  onClick={()=>handleProjectClick(item)}/>
          ))}
           
        </div>
       </div>
    <CreateProject show={openCreateProject}  onClose={()=>{ projectFetchfromDB() ; setOpenCreateproject(false)} } />
    </div>
</>
  )
}

export default ProjectList