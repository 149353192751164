import axios from "axios";
import { Url_fetch } from "../UrlConnection/Url";

const url = Url_fetch();

export const login_User =async(userid,password)=>{
    try{
        const login = await axios.post(`${url}/user/userlogin`,{
            "email":userid,
            "password":password
        })
        console.log("login",login);
        return  login;
    }
    catch(error){
        console.error(error);
        return await error;
    }
}

// supper admin login
export const supperAdminLogin =async(email,password)=>{
    try{
        const adminLogin = await axios.post(`${url}/superAdmin/admin-login`,{
            "email":email,
            "password":password
        });
        console.log(adminLogin)
        return adminLogin;
    }
    catch(error){
        console.error(error);
        return error;
    }
}

