import React, { useState } from 'react'
import './loginuser.css';
import Login_Box from '../../components/containers/LoginBox/Login_Box';
import { isMobile } from 'react-device-detect';
import Button19 from '../../components/Buttons/Button19';
import { useNavigate } from 'react-router-dom';
import { Card } from '@mui/material';
import SignupBox from '../../components/containers/signupBox/SignupBox';

const UserLogin = () => {

const [loginPage , setLoginPage] = useState(true);
  const navigate = useNavigate();
const pageStateChange =()=>{
  setLoginPage(!loginPage)
}
  return (
    <>
    <div className='login-outer-container'>
     <div className='login-inner-box'>
      {/* left box */}
      <div className='login-box-left'>
        {loginPage? 
        <Login_Box pageChange={pageStateChange} />
        :
        <SignupBox pageChange={pageStateChange}/>
        }
      </div>
      {/* right box */}
      <div className='login-box-right'>
        <div className='img-container'>
          <img src={require('../../images/img2.png')} className='img-style' />
        </div>
        <p className='title1'>Welcome To Form Application</p>
        <p className='title2'>Povered By ICTAK</p>
      </div>
     </div>
    </div>
    </>
  )
}

export default UserLogin
