import axios from "axios";
import { Url_fetch } from "../UrlConnection/Url";
import keyServices from "../service/KeyServices";

const url = Url_fetch();

// create project
export const createNewProject = async (project) => {
   try{
    const response = await axios.post(`${url}/project/createProject`,project,{
        headers:{
            Authorization:sessionStorage.getItem('token')
        }
    });
    return response;
   }
   catch(error){
       return error;
   }
};

// fetch all projects
export const getchAllProjects = async () => {
    try{
    console.log("token",keyServices.getToken());
        const response = await axios.get(`${url}/project/getallProjects`,{
            headers:{
                Authorization:keyServices.getToken()
            }
        });
        return response;
    }
    catch(error){
        return error;
    }
};