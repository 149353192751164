
import  { useState } from "react";
import './modal.css'
import { createNewProject } from "../../API/Project";
import { ToastContainer, toast } from 'react-toastify';
const CreateProject = ({ show, onClose, title, setTitle, description, setDescription, handleCreateProject }) => {
  const [project , setproject] = useState({
    projectName:"",
    projectDescription:""
  });

  const [errors ,setErrors] = useState({
    projectName:"dfdg fhgh",
    projectDescription:""
  })
 
const handleChange = (e) => {
    const {name , value} = e.target;
    setproject({
      ...project,
      [name]:value
    });
    setErrors({...errors,[name]:''});
  }
  const validateField =()=>{
    const obj ={};
    if(project.projectName === ''){
      obj.projectName ="Required"
    }
    if(project.projectDescription ===''){
      obj.projectDescription = 'Required'
    }
    if(project.projectName !=='' && project.projectName.length >= 18){
      obj.projectName ='Project name is too long'
    }
    setErrors({...errors , ...obj});
    return Object.keys(obj).length === 0;
  }
  const addProject =async()=>{
    const val = validateField();
    if(val){
    const res = await createNewProject(project);
    if(res.status === 200){
      setproject({
         projectName:"",
      projectDescription:""
      })
      toast.success(res.data.message);
      onClose();
    }
    else{
     toast.error(res.response.data.message);
    }
  }
  }

  if (!show) return null; // Hide modal if 'show' is false
  return (
    <>
    <div className="modal-overlay">
      <div className="modal-content">
        <h2 className="modal-title">Create New Project</h2>
        <div className="modal-body">
          <div className="form-group">
            <label htmlFor="title">Project Title</label>
            <input
             name="projectName"
              value={project.projectName}
              onChange={handleChange}
              placeholder="Enter project title"
              className="input-field"
            />
            <p className="projct-error-txt">{errors.projectName}</p>
          </div>
          <div className="form-group">
            <label htmlFor="description">Description</label>
            <textarea
              name="projectDescription"
              value={project.projectDescription}
              onChange={handleChange}
              placeholder="Enter project description"
              className="textarea-field"
            />
               <p className="projct-error-txt">{errors.projectDescription}</p>
          </div>
          <div className="modal-actions">
            <button className="btn-primary" onClick={addProject} >Create Project</button>
            <button className="btn-outline" onClick={onClose}>Cancel</button>
          </div>
        </div>
      </div>
    </div>
     <ToastContainer
     position='top-right'
     autoClose={2000}
     hideProgressBar={false}
     newestOnTop={false}
     closeOnClick
     rtl={false}
     pauseOnFocusLoss
     draggable
     pauseOnHover
     theme='light'
   />
    </>
  );
};

export default CreateProject;
