import React from 'react'
import './button.css'
const Buttton18 = ({Click,label}) => {
  return (
   <>
    <button class="button-18" role="button" onClick={Click}>{label}</button>
   </>
  )
}

export default Buttton18
