import React, { useState } from "react";
import "./form.css";
import WidgetDetailsDialog from "../../components/Dialog/Widget_datafetch";
import { Button } from "@mui/material";
import DeleteIcon from "@mui/icons-material/Delete";
import Accordion from "@mui/material/Accordion";
import AccordionSummary from "@mui/material/AccordionSummary";
import AccordionDetails from "@mui/material/AccordionDetails";
import Typography from "@mui/material/Typography";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import axios from "axios";
import { useNavigate } from "react-router-dom";

import Dialog_box from "../../components/Copy_dialog/Copy_url";
import { Url_fetch } from "../../UrlConnection/Url";
import { Create_form } from "../../API/Insert_data";
import AppBarCustom from "../../container/Appbar/AppBar";
import { ToastContainer, toast } from 'react-toastify';
import Header from "../../container/Header/Header";
import { useSelector } from "react-redux";
import { 
  TextIcon, 
  ListIcon, 
  CheckSquareIcon,
  CircleSmall,
  CalendarIcon,
  MailIcon,
  PhoneIcon,
  FileTextIcon,
  ImageIcon,
  StarIcon,
  List,
  CaseSensitive
} from "lucide-react";


const FormCreation = () => {
  const projectid = useSelector((state) => state.projectId.projectid);
  const navigate =  useNavigate();
  // const server url
  const url = Url_fetch();
  const [widget, setWidget] = useState([]);
  const [widgetId, setWidgetId] = useState(""); //store the unique id of  each widget
  const [openDialog, setOpendialog] = useState(false); // variable for open the dialog who is fetch the widget data,
  const [TypeofWidget, setTypeofWidget] = useState("");
  const [copyurlDialogOpen, SetCopyurlDialogOpen] = useState(false); //variable for open the url copy popup
  const [formUrl, setFormUrl] = useState(""); //store the url of the generated form
  const [formTitleImage , setFormTitleImage] = useState('')
  // fetch the url
  const publishurl = window.location.href;
  const handleOpenDialog = () => {
    setOpendialog(true);
  };

  const handleClose = () => {
    setOpendialog(false);
  };

  const handleCloseDialog = (event, reason) => {
    if (reason && reason === "backdropClick") return;
    handleClose();
  };

  const handleOndragStart = (e, widgetType) => {
    const draggedElemnt = e.target.cloneNode(true);
    e.dataTransfer.setData("text/html", draggedElemnt.innerHTML); // Fix typo
    e.dataTransfer.setData("widgetType", widgetType);
  };

  // drop the widget
  const handleDropWidget = (e) => {
    const widgetType = e.dataTransfer.getData("widgetType");
    console.log("WidgetTypeget on drop", widgetType);
    // assign the type of the widget to the variable TypeofWidget
    setTypeofWidget(widgetType);
    const draggedContent = e.dataTransfer.getData("text/html"); // Fix typo
    const newWidget = (
      <div 
        className="drop-widget-style"
        dangerouslySetInnerHTML={{ __html: draggedContent }}
      ></div>
    );
    const widgetid = `field_${new Date().getTime()}`;
    setWidgetId(widgetid);
    setWidget([
      ...widget,
      { widget: newWidget, widgetid, data: null, widgetType: widgetType },
    ]);
    handleOpenDialog(); // Make sure this is called after setting the widget
    // e.dataTransfer.clearData();
  };

  // draging is allowed to the grop area
  const handleDragOver = (e) => {
    e.preventDefault();
  };

  const handleSaveWidgetDetails = (field, widgetname, id) => {
    console.log("save......****", field, widget, id);
    handleCloseDialog();
    const updateWidgets = widget.map((widget) =>
      widget.widgetid === id ? { ...widget, data: field } : widget
    );
    setWidget(updateWidgets);

    console.log("widgetjjjjj", widget);
  };

  // remove the widget from thre list
  const handleDeleteWidget = (index) => {
    const updatedWidgets = [...widget];
    updatedWidgets.splice(index, 1);
    setWidget(updatedWidgets);
  };

  // handle remove the widget when close the diaog
  const dialogCloseandRemoveWidget = async (widgetid) => {
    await setWidget(widget.filter((item) => item.widgetid !== widgetid));
    await handleClose();
  };

  // handlign create form
  const handleCreateForm = async (widgetdata) => {
    const topurl = new URL(publishurl);
    const urlgenerate = topurl.origin + '/form/'
    console.log("widget Data",formTitleImage,"_____________", widgetdata);
    if (title !== "") {
      const formData = [];
      await widgetdata.forEach((element) => {
        formData.push({
          label: element.data.label,
          name: element.data.name,
          widgetType: element.widgetType,
          type: element.data.type,
          listItems: element.data.listArray,
          fileType: element.data.fileTypeArray,
          required: element.data.required,
          unique: element.data.unique,
          fileSize:element.data.filesize,
        });
      });
      console.log("Form data", formData);


      const uploadData = await Create_form(title, formData,urlgenerate,formTitleImage,projectid);

      if (uploadData.data) {
        console.log("Update the data", uploadData);
        const formid = uploadData.data.form_id;
        console.log("generated form url", publishurl);
        
        const generatedUrl = `/form/${formid}`;
        setFormUrl(topurl.origin + generatedUrl);
        //alert("Form Created");
        toast.success('Form Created');
        // navigate(`/form/${formid}`);
         handleOpenCopyURLDialog();
      } else {
        //alert(uploadData.response.data.message);
        toast.error(uploadData.response.data.message);
      }
    
    } 
    
    else {
      //alert("Title Cannot be empty");
      toast.error('Title Cannot be empty');
    }
  };

  const [title, setTitle] = useState("Untitled"); // store the title value
  const handleTitleChange = (e) => {
    setTitle(e.target.value);
  };

  //  copy the generated dynamic url of created form
  const handleCopyFormUrl = () => {
    navigator.clipboard
      .writeText(formUrl)
      .then(() => {
        console.log("URL copied successfully");
      })
      .catch((error) => {
        console.log("Error in copy url", error);
      });
  };

  // open the url copy dialog
  const handleOpenCopyURLDialog = () => {
    SetCopyurlDialogOpen(true);
  };
  // close the url copy dialog
  const handleCloseTheFormURLCopyPopPup = () => {
    SetCopyurlDialogOpen(false);
  };

  // const handleChangeOfTitleImage=(e)=>{
  //   console.log("filename",e.target.value);
  //   const {name,value} = e.target;
  //   const filename = e.target.files[0];
  //   console.log("filr",filename,name)
  //   setFormTitleImage(filename);

  // }

  const handleChangeOfTitleImage = (e) => {
    const file = e.target.files[0];
    const reader = new FileReader();

    reader.onload = function(event) {
        const img = new Image();
        img.src = event.target.result;

        img.onload = function() {
            const width = img.width;
            const height = img.height;

            if (width === 1260 && height === 300) {
                // Image has dimensions of 1260px width and 300px height
                console.log("Uploaded image has dimensions of 1260px width and 300px height");
                setFormTitleImage(file);
            } else {
                // Image does not have the required dimensions
                console.log("Uploaded image does not have dimensions of 1260px width and 300px height");
                // Reset the file input field
                e.target.value = ""; // Reset file input value to clear the selected file
            }
        };
    };

    reader.readAsDataURL(file);
};

// icons of the form
const components = [
  { type: "text", label: "Text Input", icon: <TextIcon className="form-icon" /> },
  { type: "radio", label: "Radio Button", icon: <CircleSmall className="form-icon" /> },
  {type:'list',label:'List',icon:<List className="form-icon"/>},
  { type: "checkbox", label: "Checkboxes", icon: <CheckSquareIcon className="form-icon" /> },
  { type: "date", label: "Date", icon: <CalendarIcon className="form-icon" /> },
  // { type: "email", label: "Email", icon: <MailIcon className="form-icon" /> },
  { type: "label", label: "Text", icon: <CaseSensitive className="form-icon" /> },
  { type: "textarea", label: "Long Text", icon: <FileTextIcon className="form-icon" /> },
  { type: "file", label: "File Upload", icon: <ImageIcon className="form-icon" /> },
  { type: "rating", label: "Rating", icon: <StarIcon className="form-icon" /> },
 
];

  return (
    <>
    {/* <AppBarCustom/> */}
    <Header/>
       <div className="drag-drop-main-container">
      <div className="drag-container">
        <button onClick={() => navigate(`/home/${projectid}`)} className="form-back-button">
          <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" width="24" height="24" stroke-width="2">
            <path d="M20 15h-8v3.586a1 1 0 0 1 -1.707 .707l-6.586 -6.586a1 1 0 0 1 0 -1.414l6.586 -6.586a1 1 0 0 1 1.707 .707v3.586h8a1 1 0 0 1 1 1v4a1 1 0 0 1 -1 1z"></path>
          </svg>
          Back
        </button>
        {components.map((item) => (
           <div
           className="drag-item-container"
           draggable
           onDragStart={(e) => handleOndragStart(e, item.type)}
         >
          {item.icon}
          <p>{item.label}</p>
         </div>
        ))}
        
      </div>

      <div className="drop-main-container-outer">
      
        <div
          className="drop-container-style"
          onDrop={handleDropWidget}
          onDragOver={handleDragOver}
        >
          {/* <h1 className="form-title">Create Custom Form</h1> */}
          
          {/* <div className="title-uploadimage">
       
            <input
                className="input-box-title"
                placeholder="Title"
                value={title}
                onChange={handleTitleChange}
              />
            
              
              <div className="button-wrapcontainer">
                  <button className="upload-title-image-button" type="file">
                    <input name="" style={{visibility:"hidden"}}  accept="image/png,image/svg+xml" onChange={handleChangeOfTitleImage} type="file" /> 
                  </button>
                  <p className="info-text">*image with width 1260px height 300px</p>
              </div>
           
          </div> */}
           <div className="form2-title-uploadimage">
      <input
        className="form2-input-box-title"
        placeholder="Title"
        value={title}
        onChange={handleTitleChange}
      />

      <div className="form2-button-wrapcontainer">
        <label className="form2-upload-title-image-button">
          Upload Image
          <input
            type="file"
            accept="image/png,image/svg+xml"
            onChange={handleChangeOfTitleImage}
            hidden
          />
        </label>
        <p className="form2-info-text">* Image should be 1260px × 300px</p>
      </div>
    </div>
          {widget.map((widgetData, index) => (
            <div className="drop-box-container" key={index}>
              <div className="widget-drop-map-container" >
                {widgetData.widget}
                {<div onClick={() => handleDeleteWidget(index)} style={{cursor:'pointer'}}>
                <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" width="20" height="20" stroke-width="2">
                    <path d="M18 6l-12 12"></path>
                    <path d="M6 6l12 12"></path>
                  </svg>
                  </div>}
              </div>

              {widgetData.widgetid && widgetData.data && (
                <div className="size-width-style">
                  <Accordion
                    //  defaultExpanded
                    className="size-width-style"
                  >
                    <AccordionSummary
                      expandIcon={<ExpandMoreIcon />}
                      aria-controls="panel1-content"
                      id="panel1-header"
                    >
                    
                        <p className={`text-design ${widgetData.widgetType === 'label' && "text"}`}>
                        {widgetData.widgetType === "label"
                          ? widgetData.data.label
                          : widgetData.data.name.toUpperCase()}
                          </p>
                      
                    </AccordionSummary>
                    <AccordionDetails>
                      <div className="widget-data-accordian-view">
                        <p>
                          <span className="text-custom-style"> Label :</span>
                          {widgetData.data.label}
                        </p>

                        {widgetData.data.type && (
                          <p style={{ marginRight: "30px" }}>
                            {" "}
                            <span className="text-custom-style">Type :</span>
                            {widgetData.data.type}
                          </p>
                        )}
                        {widgetData.data.fileTypeArray &&
                          widgetData.data.fileTypeArray.length > 0 && (
                            <div>
                              <h4 className="list-Title">File Type</h4>
                              <ul className="ul-list-style-container">
                                {widgetData.data.fileTypeArray.map((list) => (
                                  <li>{list}</li>
                                ))}
                              </ul>
                            </div>
                          )}
                        {/* <p> <span className="text-custom-style">Required :</span>{widgetData.data.required}</p> */}
                      </div>
                      {widgetData.data.listArray &&
                        widgetData.data.listArray.length > 0 && (
                          <div className="form-list">
                            <h4 className="list-Title">List Items</h4>
                            <ul className="ul-list-style-container">
                              {widgetData.data.listArray.map((list, index) => (
                                <li key={index} className="ul-list-li">{list}</li>
                              ))}
                            </ul>
                          </div>
                        )}
                    </AccordionDetails>
                  </Accordion>
                </div>
              )}
            </div>
          ))}
          {widget.length > 0 && (
            <div className="button-view-container">
              <Button
                className="button-style"
                style={{backgroundColor:'#413f3f'}}
                variant="contained"
                onClick={() => {
                  handleCreateForm(widget);
                }}
              >
                {" "}
                Create
              </Button>
            </div>
          )}
        </div>
      </div>

      <WidgetDetailsDialog
        opendailog={openDialog}
        widgetType={TypeofWidget}
        handleClose={handleCloseDialog}
        handleSave={handleSaveWidgetDetails}
        widget_Id={widgetId}
        CloseAndRemove={dialogCloseandRemoveWidget}
      />

      {/*copy  url of the generted form */}

      <Dialog_box
        open={copyurlDialogOpen}
        message={"Copy URL"}
        url={formUrl}
        handleClose={handleCloseTheFormURLCopyPopPup}
        handleCopyUrl={handleCopyFormUrl}
      />
    </div>
    <ToastContainer
        position='top-right'
        autoClose={2000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
        theme='light'
      />
    </>
   
  );
};

export default FormCreation;
