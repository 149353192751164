import axios from "axios";
import { Url_fetch } from "../UrlConnection/Url";
import keyServices from "../service/KeyServices";

const url  = Url_fetch();
const token = sessionStorage.getItem('token');

export const user_Formdata_fetch_From_Server= async(projectid)=>{
    console.log("token,url",url,token);
    try{
        const response = await axios.post(`${url}/userformdata`,{
            projectId:localStorage.getItem('projectId')
        },{
            headers:{
                Authorization:keyServices.getToken()
            }
        })

        console.log("formResponse",response);
        return await response;
    }
    catch(error){
        console.error(error);
        return await error;
    }
}


export const formDataFetch_basedOnFormId =async(formid)=>{
    try{
        const formdata = axios.get(`${url}/forms/${formid}`);
        console.log(formdata);
        return await formdata;
    }
    catch(error){
        console.error("Error",error);
        return await error;
    }
}

// fetch the form url
export const formData_fetch_from_Server=async(formId,Token)=>{
    console.log("token",token);
    try{
        const parentFormData  = await axios.get(`${url}/parentFormdata/${formId}`,{
            headers:{
                Authorization:Token
            }
        });
        console.log(parentFormData);
        return  parentFormData;
        
    }
    catch(error){
        console.error("Error",error);
        return await error;
    }
}


export const registration_Data_fetch=async(formid,Token)=>{
    try{
        const reg = await axios.get(`${url}/participant/registration/${formid}`,{
            headers:{
                Authorization:Token
            }
        });
        console.log("regData",reg);
        return  reg;
    }
catch(error){
    console.error('Error',error);
    return await error;
}
};


// fetch the file
export const fetchThefile_from_server = async(filename)=>{
try{
    const filedata = await axios.get(`${url}/participant/files/${filename}`,{
        
            responseType: 'blob'
    });
    console.log(filedata);
    return filedata;
}
catch(error){
console.error(error);
return error;

}
}