import React, { useEffect, useState } from "react";
import "./loginbox.css";
import Buttton18 from "../../Buttons/Buttton18";
import { useNavigate } from "react-router-dom";
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import Email_Dialog_Box from "../../Dialog/Email_dialog";
import PasswordReset from "../../Dialog/PasswordReset";
import Cookies from "js-cookie";
import { login_User } from "../../../API/Login";
import { forgot_Password } from "../../../API/Update_data";

import AuthContext from "../../../AuthContext/AuthContext"; //for storing auth token
import { useContext } from "react";
import InputField from "../../InputField/inputField";
import keyServices from "../../../service/KeyServices";

const Login_Box = ({pageChange}) => {
  const {updateToken} = useContext(AuthContext);


  const navigate = useNavigate();
  const[userData , setuserData] = useState({
    userid:'',
    password:''
  })

  const [openEmail , setOpenEmail] = useState(false);

  const [resetPassword,setResetPassword] = useState(false);
  const [resetEmail ,setResetEmail] =  useState('');

  const handleChange=(e)=>{
    const {name , value} = e.target;
    setuserData({...userData,[name]:value});
  }

  const user_Authentication=async()=>{ 
      const loginResponse = await login_User(userData.userid,userData.password);
      console.log(loginResponse);
      if(loginResponse.data){
        const token = loginResponse.data.token;
        const username = loginResponse.data.name;
        
        updateToken(token); //context for storing token in the Authcontext using use context hook
        keyServices.setToken(token);
      
  
        sessionStorage.setItem("token",loginResponse.data.token);
      
        toast.success(loginResponse.data.message);
        navigateToNext(`/project`)
      }
      else{
        toast.error(loginResponse.response.data.message);
      }
  }

  // navigation to next page
  const navigateToNext=(path)=>{
    navigate(path);
  }

  const googleSignIN =()=>{
    window.open(`${window.location.origin}/auth/google`, '_self');
  }

  const handle_Forgot_Password=()=>{
    setOpenEmail(true);
  }

  const sendEmailForOTPVerification=async(email)=>{
    console.log("ghusd",email)
    setResetEmail(email);
    
      const forgotPassword = await forgot_Password(email);

      console.log(forgotPassword);
      if(forgotPassword.data){
        //alert(forgotPassword.data.message);
      toast.info(forgotPassword.data.message);
      setOpenEmail(false);
      setResetPassword(true);
      }
      else{
        toast.error(forgotPassword.response.data.message);
      }
  }

  return (
    <>
      <div className="login-container">
      <h1>Login</h1>
        <div>
          <img onClick={googleSignIN} src={require('../../../images/google.png')} className="google-icon" alt="googlesignup" />
        </div>
        <div className="line-border">
          <div className="line"></div>
          <div className="line-txt">OR</div>
          <div className="line"></div>
          </div>
        <div className="login-input-container">
          <label className="login-label">User Id</label>
          <InputField 
            placeholder={"Email Id"}
            name={"userid"}
            value={userData.userid}
            onChange={handleChange}
            type={'text'}
          />
          <p className="login-otp">Login by OTP</p>
        </div>
      
        <div className="login-input-container">
          <label className="login-label">Password</label>
          <InputField 
            placeholder={"Password"}
            name={"password"}
            type={"password"}
            onChange={handleChange}
          />
            <p className="forgot-password" onClick={handle_Forgot_Password}>Forgot your passsword?</p>
        </div>
        <Buttton18 label={'Login'} Click={user_Authentication}/>
        <p className="signup-txt">If you don't have an account?<span onClick={pageChange} >Sign up</span> </p>
      </div>
      {/* <ToastContainer
        position='top-right'
        autoClose={2000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
        theme='light'
      />
      <Email_Dialog_Box
      open={openEmail}
      handleClose={()=>{setOpenEmail(false)}}
      sendEmail={sendEmailForOTPVerification}
      />
      <PasswordReset
      open={resetPassword}
      handleClose={()=>{setResetPassword(false)}}
      email={resetEmail}
      /> */}
    </>
  );
};

export default Login_Box;
